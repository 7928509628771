<template>
  <div class="">
    <vx-card title="Education" title-color="" subtitle="" class="mb-base">
      <template slot="actions" v-if="isEdit">
        <vs-button
          color="primary"
          @click="toggleEditPopup"
          icon="add"
        ></vs-button>
      </template>

      <vs-divider class="mt-0" />

      <div v-if="education.length !== 0">
        <div v-for="(item, index) in education" :key="index">
          <div class="mb-5">
            <div class="flex align-items-center justify-between items-start">
              <div class="flex align-items-center">
                <feather-icon svgClasses="h-6 w-6" icon="BookOpenIcon"></feather-icon>
              <h5 class="font-semibold mb-0 ml-2">
                {{ item.university }}
              </h5>
              </div>
              <div>
                <feather-icon
                  v-if="isEdit"
                  class="cursor-pointer hover:bg-grey p-2 rounded"
                  @click="toggleEditPopup($event, item, index)"
                  icon="EditIcon"
                  :svgClasses="iconSize"
                />
                <feather-icon
                  v-if="isEdit"
                  class="cursor-pointer hover:bg-grey p-2 rounded"
                  @click="showDeleteItem(item, index)"
                  icon="Trash2Icon"
                  :svgClasses="iconSize"
                />
              </div>
            </div>
            <p class="text-lg">{{ item.degree }}</p>
            <p class="text-base">{{ item.location }}</p>
            <small class="text-base text-blue"
              >{{ item.from | formatProfileDate }} -
              {{ item.to | formatProfileDate }}</small
            >
          </div>
        </div>
      </div>
      <div v-else></div>
    </vx-card>

    <education-edit-popup ref="editPopup" />
    <education-delete-popup ref="deletePopup" />

  </div>
</template>


<script>
import EducationEditPopup from "./EducationEditPopup.vue";
import EducationDeletePopup from './EducationDeletePopup.vue'

export default {
  name: "Education",
  data () {
    return {
      iconSize: "w-6 h-6"
    }
  },
  watch: {
    '$route.params.user_name' (userName) {
      this.$store.dispatch('profile/fetchEducation', userName)
    }
  },
  components: {
    EducationEditPopup,
    EducationDeletePopup
  },
  created () {
    this.$store.dispatch('profile/fetchEducation', this.$route.params.user_name)
  },
  computed: {
    isEdit () {
      return (
        this.$store.state.auth.userData.name === this.$route.params.user_name
      )
    },
    education () {
      return this.$store.state.profile.education
    }
  },
  methods: {
    showDeleteItem (item, index) {
      this.$refs.deletePopup.popupDeleteActive = true
      this.$refs.deletePopup.id = item.id
      this.$refs.deletePopup.index = index
    },
    toggleEditPopup (event, item, index) {
      if (!item) {
        this.$refs.editPopup.add()
      } else {
        this.$refs.editPopup.edit(item, index)
      }
    }
  },
};
</script>