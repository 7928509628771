<template>
    <div>
      <vs-popup title="Experience" :active.sync="popupActive">
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              label="Title of the role"
              v-model="position"
            />
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              type=""
              label="Company name"
              v-model="company"
            />
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              label="Role description"
              v-model="description"
            />
          </div>
        </div>

        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <location-select ref="locSelectRef" />
          </div>
        </div>
        
        <div class="vx-row mb-2">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <label class="text-sm">Starting date</label>
            <flat-pickr
              label="Starting Date"
              v-model="from"
              class="w-full"
            />
          </div>
          <div
            v-if="currently_working == false"
            class="vx-col sm:w-1/2 w-full mb-2"
          >
            <label class="text-sm">Ending date</label>
            <flat-pickr
              label="Ending Date"
              v-model="to"
              class="w-full"
            />
          </div>
        </div>

        <div class="flex flex-wrap justify-between">
          <vs-checkbox v-model="currently_working" class="mb-3"
            >Currently working</vs-checkbox
          >
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button @click="insertUpdateUserExperiences()" class="float-right"
              >Submit</vs-button
            >
          </div>
        </div>
      </vs-popup>
    </div>
    <!--End Experience Popup (Add/Edit) -->
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { createErrorObject } from '@/utils'
import LocationSelect from '../LocationSelect'

export default {
  name: 'ExperienceEditPopup',
  components: {
    flatPickr,
    LocationSelect
  },
  data () {
    return {
      currently_working: false,
      isAdd: false,
      popupActive: false,
      id: '',
      position: '',
      company: '',
      from: '',
      to: '',
      description: '',
      index: ''
    }
  },
  methods: {
    add () {
      this.currently_working = false
      this.popupActive = true
      this.id = ''
      this.position = ''
      this.company = ''
      this.from = null
      this.to = null
      this.description = ''
      this.isAdd = true
    },
    edit (item, index) {
      this.popupActive = true
      this.id = item.id
      this.index = index
      this.position = item.position
      this.company = item.company
      this.from = item.from
      this.to = item.to
      this.$refs.locSelectRef.selected = true
      this.$refs.locSelectRef.locationSelect = item.location
      this.$refs.locSelectRef.locationInput = item.location
      if (this.to === '' || this.to === null) {
        this.currently_working = true
      } else {
        this.currently_working = false
      }
      this.description = item.description
      this.isAdd = false
    },
    insertUpdateUserExperiences () {
      if (this.position === '') {
        this.$vs.notify(createErrorObject('Error', 'Position field is empty'))
        return false
      }
      if (this.company === '') {
        this.$vs.notify(createErrorObject('Error', 'Company field is empty'))
        return false
      }
      if (this.description === '') {
        this.$vs.notify(createErrorObject('Error', 'Description field is empty'))
        return false
      }
      if (this.from === '' || this.from === null) {
        this.$vs.notify(createErrorObject('Error', 'Starting Date field is empty'))
        return false
      }
      if (
        this.currently_working === false &&
        (this.to === '' || this.to === null)
      ) {
        this.$vs.notify(createErrorObject('Error', 'Ending Date field is empty'))
        return false
      }
      if (this.currently_working === true) {
        this.to = ''
      }

      if (this.isAdd) {
        this.$store.dispatch('profile/createExperience', {
          user: this.$route.params.user_name,
          position: this.position,
          company: this.company,
          from: new Date(this.from),
          to: this.to ? new Date(this.to) : null,
          description: this.description,
          location: this.$refs.locSelectRef.locationInput
        }).then(() => this.popupActive = false)
      } else {
        this.$store.dispatch('profile/updateExperience', {
          index: this.index,
          id: this.id,
          user: this.user,
          position: this.position,
          company: this.company,
          from: new Date(this.from),
          to: this.to ? new Date(this.to) : null,
          description: this.description,
          location: this.$refs.locSelectRef.locationInput
        }).then(() => this.popupActive = false)
      }
    }
  },
}
</script>

<style>

</style>
