<template>
  <div>
    <vx-card title="About me" class="mb-base">
      <template slot="actions" v-if="isEdit">
        <vs-button @click="toggleEditPopup" color="primary" icon="add"></vs-button>
      </template>
      <vs-divider class="mt-0" />

      <div v-if="about">{{ about }}</div>
      <!-- <div v-else>How would you describe yourself?</div> -->
    </vx-card>
    <vs-popup title="About me" :active.sync="popupActive">
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <vs-textarea counter="400" label="About me" v-model="updateAboutState" />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button @click="updateUserAbout()" class="mr-3 mb-2"
              >Submit</vs-button
            >
          </div>
        </div>
      </vs-popup>
  </div>
</template>

<script>
import { createErrorObject } from '@/utils'
export default {
  name: 'About',
  data () {
    return {
      popupActive: false,
      updateAboutState: ''
    }
  },
  computed: {
    about () {
      return this.$store.state.profile.profile.about
    },
    isEdit () {
      return this.$store.state.auth.userData.name === this.$route.params.user_name
    },
  },
  methods: {
    toggleEditPopup () {
      this.popupActive = true
      this.updateAboutState = this.about
    },
    updateUserAbout () {
      if (this.updateAboutState.length > 400) {
        this.$vs.notify(createErrorObject('Error', 'About field has reach maximum length 200'))
        return false
      }
      this.$store.dispatch('profile/updateAbout', {
        userName: this.$route.params.user_name,
        about: this.updateAboutState
      })
    }
  }
}
</script>