<template>
  <div id="profile-page">
    <vx-card class="mb-base">
      <!-- PROFILE HEADER -->
      <div class="profile-header">
        <div class="flex items-start justify-between">
     
          <div class="flex w-11/12">
            <div>
              <vs-avatar
                :src="userData.photoURL !== '' ? userData.photoURL : ''" 
                class="user-profile-img" size="90px" />          
            </div>
            <div class="ml-4">
              <div class="flex items-center">
                <h4 class="font-semibold mr-5">{{ userData.displayName }}</h4>
                <ProfileFollowButton v-if="!isEdit" />
              </div>

              <div class="tagline-text text-lg ">
                <div class="flex items-center">
                  <p>{{ userData.studyStatus }}</p>
                  <feather-icon  v-if="userData.isUniEmailVerify" class="ml-1" icon="CheckIcon" svgClasses="h-4 w-4 bg-primary rounded-full text-white"></feather-icon>
                </div>
                <div v-if="userData.headline" class="mt-3 flex items-start">
                  <feather-icon svgClasses="w-5 h-5" icon="SmileIcon" class="mr-2 mt-1" />
                  <p class="mt-0">{{ userData.headline }}</p>
                </div>
                
                <div v-if="userData.location" class="mt-3 flex items-start">
                  <feather-icon svgClasses="w-5 h-5" icon="MapPinIcon" class="mr-2 mt-1" />
                  <p>{{ userData.location }}</p>
                </div>
              </div>

            </div>
          </div>
          <vs-button color="primary" icon="edit" @click="toggleEditPopup" v-if="isEdit"></vs-button>
        </div>
      </div>

    <!-- Headline Popup -->
    <div>
      <vs-popup title="Update profile" :active.sync="headLinePopupActive">
        <!-- <vs-input class="w-full" label="My Status" v-model="headline" rows="2" /> -->
        <vs-textarea counter="200" class="w-full mt-base" label="Headline" v-model="headline" />
        <div><label for="Location"></label></div>
        <location-select ref="locSelectRef" />
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button class="mr-3 mt-4" @click="updateAboutAndHeadline">Update</vs-button>
          </div>
        </div>
      </vs-popup>
    </div>
    </vx-card>
  </div>
</template>

<script>
import ProfileFollowButton from './Follow'
import LocationSelect from './LocationSelect'
import { createErrorObject } from '@/utils'

export default {
  name: 'ProfileHeader',
  data () {
    return {
      headLinePopupActive: false,
      coverProfile: false,
      headline: '',
    }
  },
  components: {
    ProfileFollowButton,
    LocationSelect
  },
  watch: {
    '$route.params.user_name' (userName) {
      this.$store.dispatch('profile/fetchProfile', userName)
    }
  },
  computed: {
    isEdit () {
      return this.$store.state.auth.userData.name === this.$route.params.user_name
    },
    userData () {
      return this.$store.state.profile.profile
    }
  },
  methods: {
    onImageChange (e) {
      this.updateAvatarImage = e.target.files[0]
      const fr = new FileReader()
      fr.onload = (function (previewAvatarImage) {
        return function (e) {
          previewAvatarImage.src = e.target.result
        }
      })(this.previewAvatarImage)
      fr.readAsDataURL(this.updateAvatarImage)
    },
    toggleCoverImgUpdate () {
      this.$refs.coverImgPopup.coverProfile = true
    },
    setCoverPhotoURL (url) {
      this.coverImgUrl = url
    },
    updateUserProfileImages () {
      if (this.updateAvatarImage === '' && this.updateCoverImage === '') {
        this.$vs.notify({
          title: 'Failed',
          text: 'No image selected to upload',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } else {
        this.$vs.loading()
        this.$store.dispatch('auth/updateUserProfileImages', {
          email: this.userData.email_id,
          updateAvatarImage: this.updateAvatarImage,
          updateCoverImage: this.updateCoverImage
        })
      }
    },
    updateAboutAndHeadline () {
     
      if (this.headline.length > 200) {
        this.$vs.notify(createErrorObject('Error', 'Headline field reach maximum length 200'))
      }
      this.$store.dispatch('profile/updateProfileHeader', {
        userName: this.$route.params.user_name,
        headline: this.headline,
        location: this.$refs.locSelectRef.locationInput
      })
      this.headLinePopupActive = false
    },
    toggleEditPopup () {
      this.headLinePopupActive = true
      this.headline = this.$store.state.profile.profile.headline
      this.$refs.locSelectRef.selected = true
      this.$refs.locSelectRef.locationSelect = this.$store.state.profile.profile.location 
      this.$refs.locSelectRef.locationInput = this.$store.state.profile.profile.location
    }
  },
  created () {
    this.$store.dispatch('profile/fetchProfile', this.$route.params.user_name)
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.my-profile-img-container {
  display: flex;
  align-items: center;
  position: absolute;
  // bottom: -5.7rem;
  bottom: -1.4rem;
  left: 10%;
  width: 80%;
  justify-content: space-between;
}

.cover-profile-img {
  height: 450px;
  width: 100%;
  object-fit: cover;
}
.z-200 {
  z-index: 200!important;
}
</style>