<template>
    <div>
      <vs-popup
        title="Achievement"
        :active.sync="popupActive"
      >
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              label="Award or achievement"
              v-model="title"
            />
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              type=""
              label="Issued by"
              v-model="issueBy"
            />
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              label="Description "
              v-model="description"
            />
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full mb-2">
            <label class="text-sm">Date</label>
            <flat-pickr label="Starting Date" v-model="issueDate" class="w-full" />
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full mb-2">
            <location-select ref="locSelectRef" />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button @click="insertUpdateUserAchievements()" class="mr-3 mb-2"
              >Submit</vs-button
            >
            <!-- <vs-button color="warning" type="border" class="mb-2" @click="input9 = input10 = input11 = input12 = ''; check3 = false;">Reset</vs-button> -->
          </div>
        </div>
      </vs-popup>
    </div>
    <!--End Experience Popup (Add/Edit) -->
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { createErrorObject } from '@/utils'
import LocationSelect from '../LocationSelect'

export default {
  name: 'Achievement',
  data () {
    return {
      isAdd: false,
      popupActive: false,
      id: '',
      index: '',
      title: '',
      issueBy: '',
      issueDate: '',
      description: '',
    }
  },
  components: {
    flatPickr,
    LocationSelect
  },
  methods: {
    add () {
      this.popupActive = true
      this.id = ''
      this.title = ''
      this.issueBy = ''
      this.issueDate = ''
      this.description = ''
      this.isAdd = true
    },
    edit (item, index) {
      this.popupActive = true
      this.id = item.id
      this.index = index
      this.title = item.title
      this.issueBy = item.issueBy
      this.issueDate = item.issueDate
      this.description = item.description
      this.isAdd = false
      this.$refs.locSelectRef.selected = true
      this.$refs.locSelectRef.locationSelect = item.location
      this.$refs.locSelectRef.locationInput = item.location
    },
    insertUpdateUserAchievements () {
      if (this.title === '') {
        this.$vs.notify(createErrorObject('Error', 'Title field is missing'))
        return false
      }
      if (this.issueBy === '') {
        this.$vs.notify(createErrorObject('Error', 'Issue By field is missing'))
        return false
      }
      if (this.description === '') {
        this.$vs.notify(createErrorObject('Error', 'Description field is missing'))
        return false
      }
      if (this.issueDate === '' || this.issueDate === null) {
        this.$vs.notify(createErrorObject('Error', 'Issue Date field is missing'))
        return false
      }

      if (this.isAdd) {
        this.$store.dispatch('profile/createAchievement', {
          user: this.$route.params.user_name,
          title: this.title,
          issueBy: this.issueBy,
          issueDate: new Date(this.issueDate),
          description: this.description,
          location: this.$refs.locSelectRef.locationInput
        }).then(() => this.popupActive = false)
      } else {
        this.$store.dispatch('profile/updateAchievement', {
          id: this.id,
          user: this.$route.params.user_name,
          index: this.index,
          title: this.title,
          issueBy: this.issueBy,
          issueDate: new Date(this.issueDate),
          description: this.description,
          location: this.$refs.locSelectRef.locationInput
        }).then(() => this.popupActive = false)
      }
    },
  }
}
</script>
