<template>
  <div>
    <vs-button size="small" v-if="isFollowed" @click="unFollowUser">Unfollow</vs-button>
    <vs-button size="small" v-else @click="followUser">Follow</vs-button>
  </div>
</template>

<script>
import { API, graphqlOperation } from '@aws-amplify/api'
import { getFollow } from '@/graphql/queries'

export default {
  name: 'ProfileFollowButton',
  data () {
    return {
      isFollowed: false
    }
  },
  methods: {
    async checkFollow () {
      const res = await API.graphql(graphqlOperation(getFollow, {
        user: this.$route.params.user_name,
        follower: this.$store.state.auth.userData.name
      }))
      if (res.data.getFollow !== null) {
        this.isFollowed = true
      } else {
        this.isFollowed = false
      }
    },
    followUser () {
      this.$store.dispatch('follow/followUser', this.$route.params.user_name).then(() => {
        this.isFollowed = true
      })
    },
    unFollowUser () {
      this.$store.dispatch('follow/unfollowCurrentAuthor', this.$route.params.user_name).then(() => {
        this.isFollowed = false
      })
    }
  },
  created () {
    this.checkFollow()
  }
}
</script>