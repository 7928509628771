<template>
   <!-- Education Popup (Delete) -->
    <div>
      <vs-popup
        title="Confirm"
        :active.sync="popupDeleteActive"
      >
        <div class="vx-row mb-2">
          <div class="vx-col w-full mb-3 mt-3">
            <span>Are you sure you want to delete?</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button size="small" @click="deleteItem()" class="mr-3 mb-2"
              >Delete</vs-button
            >
            <vs-button
              size="small"
              type="border"
              color="warning"
              @click="popupDeleteActive = false"
              class="mr-3 mb-2"
              >Cancel</vs-button
            >
          </div>
        </div>
      </vs-popup>
    </div>
    <!--End Education Popup (Delete) -->
</template>

<script>
export default {
  name: 'AchievementDeletePopup',
  data () {
    return {
      popupDeleteActive: false,
      id: '',
      index: ''
    }
  },
  methods: {
    deleteItem () {
      this.$store.dispatch('profile/deleteAchievement', { id: this.id, index: this.index }).then(() => {
        this.popupDeleteActive = false
      })
    }
  }
}
</script>