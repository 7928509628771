<template>
  <div class="">
    <vx-card title="Work Experiences" title-color="" subtitle="" class="mb-base">
      <template slot="actions" v-if="isEdit">
        <vs-button @click="toggleEditPopup" color="primary" icon="add"></vs-button>
      </template>

      <vs-divider class="mt-0"/>

      <div v-if="experience.length !== 0">
        <div v-for="(item, index) in experience" :key="index">
          <div class="mb-5">
            <div class="flex align-items-center justify-between items-start">
               <div class="flex align-items-center">
                <feather-icon svgClasses="h-6 w-6" icon="BriefcaseIcon"></feather-icon>
              <h5 class="font-semibold mb-0 ml-2">{{ item.position }}</h5>
               </div>
              <div>
                <feather-icon
                  v-if="isEdit"
                  class="cursor-pointer hover:bg-grey p-2 rounded"
                  @click="toggleEditPopup($event, item, index)"
                  icon="EditIcon"
                  :svgClasses="iconSize"
                />
                <feather-icon
                  v-if="isEdit"
                  class="cursor-pointer hover:bg-grey p-2 rounded"
                  @click="showDeleteItem(item, index)"
                  icon="Trash2Icon"
                  :svgClasses="iconSize"
                />
              </div>
            </div>
            <p class="text-lg">{{ item.company }}</p>
            <p>{{ item.description }}</p>
            <p class="text-base">{{ item.location }}</p>
            <small class="text-blue text-base"
              >{{ item.from | formatProfileDate }} -
              {{ item.to | formatProfileDate }}</small
            >
          </div>
        </div>
      </div>
      <div v-else>-</div>
    </vx-card>

    <experience-edit-popup ref="editPopup"/>
    <experience-delete-popup ref="deletePopup" />

  </div>
</template>

<script>
import ExperienceEditPopup from './ExperienceEditPopup.vue'
import ExperienceDeletePopup from './ExperienceDeletePopup.vue'

export default {
  name: 'Experience',
  data () {
    return {
      iconSize: 'h-6 w-6',
    }
  },
  watch: {
    '$route.params.user_name' (userName) {
      this.$store.dispatch('profile/fetchWorkExperience', userName)
    }
  },
  components: {
    ExperienceEditPopup,
    ExperienceDeletePopup
  },
  computed: {
    isEdit () {
      return this.$store.state.auth.userData.name === this.$route.params.user_name
    },
    experience () {
      return this.$store.state.profile.experience
    }
  },
  created () {
    this.$store.dispatch('profile/fetchWorkExperience', this.$route.params.user_name)
  },
  methods: {
    showDeleteItem (item, index) {
      this.$refs.deletePopup.popupDeleteActive = true
      this.$refs.deletePopup.id = item.id
      this.$refs.deletePopup.index = index
    },
    toggleEditPopup (e, item, index) {
      if (!item) {
        this.$refs.editPopup.add()
      } else {
        this.$refs.editPopup.edit(item, index)
      }
    }
  }
}
</script>