<template>
  <div>
    <input v-if="isBootstrap" class="form-control" v-model="locationSelect" />
    <vs-input v-else ref="locInputRef" v-model="locationSelect" :label="title ? title: 'Location'" class="w-full" />
    <ul class="border-gray-50 shadow-md rounded bg-white" :class="isAbsolute ? 'absolute' : '' ">
      <li class="cursor-pointer hover:bg-blue hover:text-white p-2" 
        @click="setLocation(item, index)" 
        :key="index" 
        :value="item"
        v-for="(item, index) in locations">{{ item }}</li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'LocationSelect',
  data () {
    return {
      locationInput: '',
      locationSelect: '',
      locations: [],
      selected: false
    }
  },
  props: ['isAbsolute', 'isBootstrap', 'title'],
  watch: {
    locationSelect (newVal, oldVal) {
      if (newVal !== '' && !this.selected) {
        const service = new google.maps.places.AutocompleteService()
        service.getPlacePredictions({ input: newVal }, this.setLocationList)
      } else {
        this.locations = []
        this.selected = false
      }
    }
  },
  methods: {
    setLocationList (predictions, status) {
      this.locations = predictions.map(p => p.description)
    },
    setLocation (item, index) {
      this.selected = true
      this.locationInput = item
      this.locationSelect = item
      this.locations = []
      this.$emit('onLocSelect', item)
    }
  }
}
</script>

<style lang="css">

</style>