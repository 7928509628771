<template>
  <div>
    <vs-popup title="Project" :active.sync="popupActive">
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input 
            class="w-full"
            label="Project Name"
            v-model="name" />
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <label class="vs-input--label" for="description">Project Description</label>
          <vs-textarea 
            name="description"
            class="w-full"
            v-model="description" />
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <div class="flex items-center"> 
            <vs-switch
              v-model="isWorking" />

            <span class="ml-2">Are you currently working on this project?</span>
          </div>
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Start Date</label>
          <flat-pickr 
            label="Start Date"
            v-model="startDate"
            class="w-full"
            />
        </div>
        <div v-if="!isWorking" class="vx-col w-1/2">
          <label class="vs-input--label">End Date</label>
          <flat-pickr
            label="End Date"
            v-model="endDate"
            class="w-full" 
            :config="configEndDate"
            />
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-full">
            <vs-input 
              class="w-full"
              label="Project URL"
              v-model="projectURL" />
        </div>
      </div>

      <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="float-right" @click="insertUpdateUserProject()">
          Submit</vs-button>
      </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { createErrorObject } from '@/utils'

export default {
  name: 'Project',
  data () {
    return {
      isAdd: false,
      popupActive: false,
      id: '',
      index: '',
      name: '',
      projectURL: '',
      description: '',
      contributors: [],
      startDate: '',
      endDate: '',
      configEndDate: {},
      isWorking: true
    }
  },
  watch: {
    startDate (val) {
      this.endDate = ''
      this.configEndDate =  {
        minDate: this.startDate
      }
    }
  },
  components: {
    flatPickr
  },
  methods: {
    add () {
      this.isWorking = true
      this.popupActive = true
      this.id = ''
      this.name = ''
      this.projectURL = ''
      this.description = ''
      this.contributors = []
      this.startDate = ''
      this.endDate = ''
      this.isAdd = true
    },
    edit (item, index) {
      this.popupActive = true
      this.id = item.id
      this.index = index
      this.name = item.name
      this.description = item.description
      this.projectURL = item.projectURL
      this.startDate = item.startDate
      this.configEndDate = {
        minDate: this.startDate
      }
      this.endDate = item.endDate
      this.isWorking = item.isWorking
      this.isAdd = false
    },
    insertUpdateUserProject () {
      if (this.name === '') {
        this.$vs.notify(createErrorObject('Error', 'Project Name cannot be empty'))
        return false
      }

      if (this.description === '') {
        this.$vs.notify(createErrorObject('Error', 'Project Description cannot be empty'))
        return false
      }

      if (this.startDate === '') {
        this.$vs.notify(createErrorObject('Error', 'Start Date cannot be empty'))
        return false
      }
      if (this.isAdd) {
        this.$store.dispatch('profile/createProject', {
          user: this.$route.params.user_name,
          name: this.name,
          description: this.description,
          projectURL: this.projectURL,
          contributors: this.contributors,
          startDate: this.startDate ? new Date(this.startDate) : new Date(),
          endDate: this.endDate ? new Date(this.endDate) : null,
          isWorking: this.isWorking
        }).then(() => {
          this.popupActive = false
        })
      } else {
        this.$store.dispatch('profile/updateProject', {
          id: this.id,
          user: this.$route.params.user_name,
          index: this.index,
          name: this.name,
          description: this.description,
          startDate: this.startDate ? new Date(this.startDate) : new Date(),
          endDate: this.endDate ? new Date(this.endDate) : null,
          projectURL: this.projectURL,
          contributors: this.contributors,
          isWorking: this.isWorking
        }).then(() => {
          this.popupActive = false
        })
      }
     }
  }
}
</script>