<template>
  <div>
    <vx-card title="My Expertises" title-color="" subtitle="" class="mb-base">
      <template slot="actions" v-if="isEdit">
        <vs-button
          @click="
            popupActive = true
            search = ''
          "
          color="primary" icon="add"
          ></vs-button
        >
        <!-- <feather-icon icon="MoreHorizontalIcon"></feather-icon> -->
      </template>
      <vs-divider class="mt-0" />
      <div class="skills-div">
        <div v-for="(item, index) in skills" :key="index">
          <vs-chip color="" transparent :closable="isEdit" @click="deleteSkill($event, item, index)"><span class="p-2 text-base" >{{ item.skillLabel }}</span> </vs-chip>
        </div>
      </div>
    </vx-card>

    <div>
      <vs-popup
        class="skills-popup"
        title="My Expertises"
        :active.sync="popupActive"
      >
        <div>
          <div class="vx-row mb-2">
            <div class="vx-col w-full">
              <vs-input class="w-full" placeholder="Search" v-model="search" />
            </div>
          </div>
          <ul class="px-2">
            <li
              v-for="(skill, index) in filteredSkills"
              :key="index"
              class="flex py-2 px-2 mx-2 my-2"
            >
              <div class="channel-button">
                <div class="channel-item">{{ skill.sub_skill_name }}</div>
              </div>
              <div class="ml-auto">
                <vs-button
                  radius
                  @click="createSkill($event, skill)"
                  color="primary"
                  icon="add"
                  ></vs-button>
              </div>
            </li>
          </ul>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import axios from 'axios'

export default {
  name: 'Skills',
  data () {
    return {
      popupActive: false,
      search: '',
      loading: false,
      filteredSkills: [],
      suggestSkillList: []
    }
  },
  watch: {
    search (oldVal, newVal) {
      if (oldVal === '') {
        this.filteredSkills = this.suggestSkillList
      } else {
        this.filteredSkills = this.suggestSkillList.filter(skill => {
          return skill.sub_skill_name.includes(oldVal)
        })
      }
    },
    '$route.params.user_name' (userName) {
      this.$store.dispatch('profile/fetchSkills', userName)
    }
  },
  computed: {
    isEdit () {
      return this.$store.state.auth.userData.name === this.$route.params.user_name
    },
    skills () {
      return this.$store.state.profile.skills
    },
    skillLabels () {
      return this.$store.state.profile.skills.map(skill => skill.skillLabel)
    }
  },
  methods: {
    createSkill (event, skill) {
      if (!this.skillLabels.includes(skill.sub_skill_name)) {
        this.$store.dispatch('profile/createSkill', {
          user: this.$route.params.user_name,
          skillLabel: skill.sub_skill_name
        }).then(() => {
          this.suggestSkillList = this.suggestSkillList.filter(suggestSkill => {
            return suggestSkill.sub_skill_name !== skill.skillLabel
          })
          
        })
      }
      this.popupActive = false
    },
    deleteSkill (event, item, index) {
      this.$store.dispatch('profile/deleteSkill', { id: item.id, index })
    },
    onSuggestSelect (suggest) {
      this.selectedSubskills.push(suggest)
      this.$refs.suggestComponent.clearSuggestions()
      this.filtertext = ''
    }
  },
  components: {
    VueSimpleSuggest
  },
  created () {
    this.$store.dispatch('profile/fetchSkills', this.$route.params.user_name).then(() => {
      if (this.$route.params.user_name === this.$store.state.auth.userData.name) {
        axios
          .post(`${process.env.VUE_APP_BASE_URL}/subskillsfilter`, {
            search_key: ''
          })
          .then((res) => {
            this.suggestSkillList = res.data.data
            const skillLabels = this.$store.state.profile.skills.map(skill => skill.skillLabel)
            // filter user skills from suggested skills
            this.suggestSkillList = this.suggestSkillList.filter(skill => {
              return !skillLabels.includes(skill.sub_skill_name)
            })
            this.filteredSkills = this.suggestSkillList
          })
          .catch((err) => console.log(err))
      }
    })
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";
.skills-popup .vs-popup {
  min-height: calc(100% - 30px);
}

.vs-chip--close {
  border-radius: 50% !important;
  margin-right: 1em;
}
.skills-div .con-vs-chip{
  background-color:transparent !important;
  border: 1px solid #dc123e !important;
  // background-color:rgb(96, 155, 252);
}
.skills-div .text-chip{
  color: #dc123e !important;
  // background-color: #dc123e !important;
}
.skills-div .vs-chip--close {
  background: rgb(241, 6, 37) !important;
  // background: rgba(241, 6, 37, .65) !important;
}
</style>