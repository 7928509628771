<template>
  <div id="profile-page">
    <div class="vx-row">
      <!-- Left Col  -->
      <div class="vx-col w-full md:w-2/3">
        <ProfileHeader />
        <About />
        <Services />
        <Skills />
        <Education />
        <Experience />
        <Achievement />
        <!-- <Project /> -->
        <Certificate />
        <!-- <Certifications /> -->
      </div>
      <!-- Left Col  -->

      <!-- Right Col  -->
      <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
        <MyFeeds :author="this.$route.params.user_name" :isUniEmailVerify="isUniEmailVerify" />
        <PictureHolder
          :isLoading="isPictureHolderLoading"
          v-on:pictures-loaded="setPicturesLoaded"
        />
        <div class="Coursepal-mobile mt-base">
          <vx-card title="Coursepal">
            <b-tabs pills>
              <b-tab title="Followers">
                <my-followers :isProfile="true" />
              </b-tab>
              <b-tab title="Followings">
                <my-follows :isProfile="true"/>
              </b-tab>
            </b-tabs>
          </vx-card>
        </div>
      </div>
      <!-- Right Col  -->
    </div>
  </div>
</template>

<script>
import ProfileHeader from './ProfileHeader'
import Experience from './experience/Experience'
import Achievement from './achievement/Achievement'
import Education from './education/Education'
import Skills from './skill/Skills'
import Services from './services/Services'
import Project from './project/Project'
import Certificate from './certificate/Certificate'
import About from './about/About'
import MyFeeds from '@/layouts/components/coursepal/MyFeeds'
import MyFollows from '@/layouts/components/coursepal/MyFollows'
import MyFollowers from '@/layouts/components/coursepal/MyFollowers'
import SuggestedFollows from '@/layouts/components/coursepal/SuggestedFollows'
import PictureHolder from '@/layouts/components/coursepal/PictureHolder'
import Follow from './Follow'

export default {
  name: 'Profile',
  components: {
    ProfileHeader,
    Experience,
    Achievement,
    Education,
    Skills,
    Services,
    Project,
    Certificate,
    About,
    MyFeeds,
    MyFollows,
    MyFollowers,
    PictureHolder,
    SuggestedFollows,
    Follow
  },
  data () {
    return {
      pageLoaded : false,
      isNavOpen: false,
      wasSidebarOpen: null,
      isPictureHolderLoading: true
    }
  },
  watch: {
    '$route.params.user_name' (user) {
      this.$store.dispatch('profile/fetchFollowers', user)
      this.$store.dispatch('profile/fetchFollowings', user)
      this.$store.dispatch('profile/fetchMyFeeds', user).then(() => {
        this.isPictureHolderLoading = false
      })
    }
  },
  computed: {
    isEdit () {
      return this.$store.state.auth.userData.name === this.$route.params.user_name
    },
    isUniEmailVerify () {
      return this.$store.state.profile.isUniEmailVerify
    }
  },
  methods: {
    setPicturesLoaded () {
      this.isPictureHolderLoading = false
    }
  },
  mounted () {
    this.wasSidebarOpen = this.$store.state.reduceButton
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
  },
  created () {
    this.$store.commit('newsfeed/SET_IS_LOADING', true)
    this.$store.dispatch('profile/fetchFollowers', this.$route.params.user_name)
    this.$store.dispatch('profile/fetchFollowings', this.$route.params.user_name)
    this.$store.dispatch('profile/fetchMyFeeds', this.$route.params.user_name).then(() => {
      this.isPictureHolderLoading = false
    })
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";

// .vx-card .vx-card__collapsible-content.vs-con-loading__container {
//     overflow: hidden !important;
// }
.my-posts.mt-base{
  margin-top: 0 !important;
}
</style>
