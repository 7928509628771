<template>
  <div>
    <vs-popup class="holamundo" title="Education" :active.sync="popupActive">
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="University" v-model="university" />
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input class="w-full" type="" label="Degree" v-model="degree" />
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col sm:w-1/2 w-full mb-2">
          <label class="text-sm">Starting Date</label>
          <flat-pickr
            label="Starting Date"
            v-model="from"
            class="w-full"
          />
        </div>
        <div
          v-if="currently_studying == false"
          class="vx-col sm:w-1/2 w-full mb-2"
        >
          <label class="text-sm">Ending Date</label>
          <flat-pickr
            label="Ending Date"
            v-model="to"
            class="w-full"
          />
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <location-select ref="locSelectRef" />
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-checkbox class="inline-flex" v-model="currently_studying"
            >Currently studying</vs-checkbox
          >
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button @click="insertUpdateUserEducation()" class="mr-3 mb-2"
            >Submit</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import "flatpickr/dist/flatpickr.css";
import flatPickr from "vue-flatpickr-component"
import { createErrorObject } from '@/utils'
import LocationSelect from '../LocationSelect'
export default {
  name: 'EducationEditPopup',
  data () {
    return {
      popupActive: false,
      currently_studying : false,
      isAdd : false,
      index: '',
      id : '',
      university : '',
      degree : '',
      from : '',
      to : '',
    }
  },
  components: {
    flatPickr,
    LocationSelect
  },
  methods: {
    add () {
      this.currently_studying = false
      this.popupActive = true
      this.id = 0
      this.university = ''
      this.degree = ''
      this.from = null
      this.to = null
      this.isAdd = true
    },
    edit (item, index) {
      this.popupActive = true
      this.id = item.id
      this.index = index
      this.university = item.university
      this.degree = item.degree
      this.from = item.from
      this.to = item.to
      this.isAdd = false
      this.$refs.locSelectRef.selected = true
      this.$refs.locSelectRef.locationSelect = item.location
      this.$refs.locSelectRef.locationInput = item.location

      if (this.to === '' || this.to === null) {
        this.currently_studying = true
      } else {
        this.currently_studying = false
      }
    },
    insertUpdateUserEducation () {
      if (this.university === '') {
        this.$vs.notify(createErrorObject('Error', 'University field is empty'))
        return false
      }
      if (this.degree === '') {
        this.$vs.notify(createErrorObject('Error', 'Degree field is empty'))
        return false
      }
      if (this.from === '' || this.from === null) {
        this.$vs.notify(createErrorObject('Error', 'Starting Date field is empty'))
        return false
      }
      if (this.currently_studying === false && (this.to === '' || this.to === null)) {
        this.$vs.notify(createErrorObject('Warning', 'Ending Date field is empty'))
        return false
      }

      if (this.currently_studying === true) {
        this.to = ''
      }

      if (this.isAdd) {
        this.$store.dispatch('profile/createEducation', {
          user: this.$route.params.user_name,
          university: this.university,
          degree: this.degree,
          from: new Date(this.from), 
          to: this.to ? new Date(this.to) : null,
          location: this.$refs.locSelectRef.locationInput
       }).then(() => this.popupActive = false)
      } else {
        this.$store.dispatch('profile/updateEducation', {
          index: this.index,
          id: this.id,
          user: this.$route.params.user_name,
          university: this.university,
          degree: this.degree,
          from: new Date(this.from), 
          to: this.to ? new Date(this.to) : null,
          location: this.$refs.locSelectRef.locationInput
        }).then(() => this.popupActive = false)
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>