<template>
  <div>
    <vs-popup title="Certificate" :active.sync="popupActive">
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input 
            class="w-full"
            label="Certificate Name"
            v-model="name" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="vs-input--label" for="description">Description</label>
          <vs-textarea 
            name="description"
            class="w-full"
            v-model="description" />
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <vs-select v-model="studyArea" label="Study Area" class="w-full">
            <vs-select-item v-for="(item, index) in studyAreaList"  :key="index" :text="item.study_area" :value="item.study_area" />
          </vs-select>
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full"
            label="Grade (optional)"
            v-model="grade" />
        </div>
      </div>

       

      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <label class="vs-input--label">Completed date or expected to complete date</label>
          <flat-pickr 
            label="Complete Date"
            v-model="completeDate"
            class="w-full"
            />
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-full">
            <vs-input 
              class="w-full"
              label="Certificate Link"
              v-model="url" />
        </div>
      </div>
    

      <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="float-right" @click="insertUpdateUserCertificate()">
          Submit</vs-button>
      </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import axios from 'axios'
import { createErrorObject } from '@/utils'

export default {
  name: 'CertificateEditPopup',
  data () {
    return {
      isAdd: false,
      popupActive: false,
      id: '',
      index: '',
      name: '',
      url: '',
      description: '',
      completeDate: '',
      grade: '',
      studyArea: '',
      studyAreaList: []
    }
  },
  components: {
    flatPickr
  },
  created () {
    axios.post(`${process.env.VUE_APP_BASE_URL  }/StudyAreasofAnUniversity`, {
      univ_id : this.$store.state.auth.userData.university
    }).then(res => {
      this.studyAreaList = res.data.data.filter(studyArea => {
        if (studyArea.stu_area_id !== null) return studyArea
      })
    })
  },
  methods: {
    add () {
      this.isWorking = true
      this.popupActive = true
      this.id = ''
      this.name = ''
      this.url = ''
      this.description = ''
      this.completeDate = ''
      this.grade = ''
      this.studyArea = ''
      this.isAdd = true
    },
    edit (item, index) {
      this.popupActive = true
      this.id = item.id
      this.index = index
      this.name = item.name
      this.description = item.description
      this.url = item.url
      this.completeDate = item.completeDate
      this.grade = item.grade
      this.studyArea = item.studyArea
      this.isAdd = false
    },
    insertUpdateUserCertificate () {
      if (this.name === '') {
        this.$vs.notify(createErrorObject('Error', 'Certificate Name cannot be empty'))
        return false
      }

      if (this.description === '') {
        this.$vs.notify(createErrorObject('Error', 'Certificate Description cannot be empty'))
        return false
      }

      if (this.completeDate === '') {
        this.$vs.notify(createErrorObject('Error', 'Complete Date cannot be empty'))
        return false
      }

      if (this.studyArea === '') {
        this.$vs.notify(createErrorObject('Error', 'Study Area cannot be empty'))
        return false
      }

      if (this.url === '') {
        this.$vs.notify(createErrorObject('Error', 'Certificate Url cannot be empty'))
        return false
      }

      if (this.isAdd) {
        this.$store.dispatch('profile/createCertificate', {
          user: this.$route.params.user_name,
          name: this.name,
          description: this.description,
          url: this.url,
          completeDate: this.startDate ? new Date(this.startDate) : new Date(),
          studyArea: this.studyArea,
          grade: this.grade ? this.grade : ''
        }).then(() => {
          this.popupActive = false
        })
      } else {
        this.$store.dispatch('profile/updateCertificate', {
          id: this.id,
          user: this.$route.params.user_name,
          index: this.index,
          name: this.name,
          description: this.description,
          completeDate: this.completeDate ? new Date(this.completeDate) : new Date(),
          url: this.url,
          grade: this.grade ? this.grade : '',
          studyArea: this.studyArea
        }).then(() => {
          this.popupActive = false
        })
      }
     }
  }
}
</script>