<template>
  <div class="">
    <vx-card
      title="Achievements"
      title-color=""
      subtitle=""
      class="mb-base recognitions-achievements"
    >
      <template slot="actions" v-if="isEdit">
        <vs-button @click="toggleEditPopup" color="primary" icon="add"></vs-button>
      </template>

      <vs-divider class="mt-0" /> 

      <div v-if="achievements.length !== 0">
        <div v-for="(item, index) in achievements" :key="index">
          <div class="mb-5">
            <div class="flex justify-between items-start">
              <div class="flex items-center">
                <feather-icon svgClasses="h-6 w-6" icon="AwardIcon"></feather-icon>
              <h5 class="font-semibold mb-0 ml-2">{{ item.title }}</h5>
              </div>
              <div>
                <feather-icon
                  v-if="isEdit"
                  class="cursor-pointer hover:bg-grey p-2 rounded"
                  @click="toggleEditPopup($event, item, index)"
                  icon="EditIcon"
                  :svgClasses="iconSize"
                />
                <feather-icon
                  v-if="isEdit"
                  class="cursor-pointer hover:bg-grey p-2 rounded"
                  @click="showDeleteItem(item, index)"
                  icon="Trash2Icon"
                  :svgClasses="iconSize"
                />
              </div>
            </div>
            <p class="text-lg">{{ item.issueBy }}</p>
            <p>{{ item.description }}</p>
            <p class="text-base">{{ item.location }}</p>
            <small class="text-blue text-base">{{ item.issueDate | formatProfileDate }}</small>
          </div>
        </div>
      </div>
    </vx-card>

    <achievement-edit-popup ref="editPopup" />
    <achievement-delete-popup ref="deletePopup" />
  
  </div>
</template>


<script>
import AchievementEditPopup from './AchievementEditPopup.vue'
import AchievementDeletePopup from './AchievementDeletePopup.vue'

export default {
  name: 'Achievement',
  data () {
    return {
      iconSize: "h-6 w-6"
    }
  },
  watch: {
    '$route.params.user_name' (userName) {
      this.$store.dispatch('profile/fetchAchievements', userName)
    }
  },
  components: {
    AchievementEditPopup,
    AchievementDeletePopup
  },
  computed: {
    isEdit () {
      return this.$store.state.auth.userData.name === this.$route.params.user_name
    },
    achievements () {
      return this.$store.state.profile.achievements
    }
  },
  created () {
    this.$store.dispatch('profile/fetchAchievements', this.$route.params.user_name)
  },
  methods: {
    showDeleteItem (item, index) {
      this.$refs.deletePopup.popupDeleteActive = true
      this.$refs.deletePopup.id = item.id
      this.$refs.deletePopup.index = index
    },
    toggleEditPopup (event, item, index) {
      if (!item) {
        this.$refs.editPopup.add()
      } else {
        this.$refs.editPopup.edit(item, index)
      }
    }
  }
}
</script>
